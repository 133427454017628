import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { getDateInKhmer } from '../../utils'

const Profile = () => {
  const data = useStaticQuery(graphql`
    query WriterDetailQuery {
      avatar: file(absolutePath: { regex: "/davychhouk.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 100, height: 100)
        }
      }
      site {
        siteMetadata {
          authors {
            id
            name
            social {
              facebook
              twitter
              github
              medium
              devTo
              stackOverflow
            }
            summary
            work
            location
            education
            joined
          }
        }
      }
    }
  `)

  const { authors } = data.site.siteMetadata
  const author = authors.find((author) => author.id === 1)
  const { github, medium, devTo } = author.social

  return (
    <div
      className="flex w-full justify-center items-center border mb-8 md:mb-12 mr-6 p-10 rounded-sm"
      style={{
        color: 'var(--textNormal)',
        borderColor: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <GatsbyImage
          image={getImage(data.avatar)}
          alt={`${author.name}'s profile picture`}
          style={{
            minWidth: 100,
            borderRadius: `100%`,
            marginBottom: 15
          }}
          imgStyle={{
            borderRadius: `50%`
          }}
        />
        <div className="font-bold text-xl mb-1">{author.name}</div>
        <div className="md:max-w-3/4 text-center mb-8">{author.summary}</div>

        <div className="flex md:hidden flex-col w-full mb-8">
          <div className="mb-4">
            <div className="mb-1">កម្រិតអប់រំ៖</div>
            <div className="font-bold">{author.education}</div>
          </div>
          <div className="mb-4">
            <div className="mb-1">ការងារ៖</div>
            <div className="font-bold">{author.work}</div>
          </div>
          <div className="mb-4">
            <div className="mb-1">នៅ៖</div>
            <div className="font-bold">{author.location}</div>
          </div>
          <div className="">
            <div className="mb-1">បានចូលរួម៖</div>
            <div className="font-bold">{getDateInKhmer(author.joined)}</div>
          </div>
        </div>

        <div className="hidden md:flex lg:hidden flex-row mb-8">
          <div className="flex flex-col mr-8">
            <div className="mb-4">
              <div className="mb-1">កម្រិតអប់រំ៖</div>
              <div className="font-bold">{author.education}</div>
            </div>
            <div className="">
              <div className="mb-1">ការងារ៖</div>
              <div className="font-bold">{author.work}</div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-4">
              <div className="mb-1">នៅ៖</div>
              <div className="font-bold">{author.location}</div>
            </div>
            <div className="">
              <div className="mb-1">បានចូលរួម៖</div>
              <div className="font-bold">{getDateInKhmer(author.joined)}</div>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex flex-row mb-12">
          <div className="mr-8">
            <div className="mb-1">កម្រិតអប់រំ៖</div>
            <div className="font-bold">{author.education}</div>
          </div>
          <div className="mr-8">
            <div className="mb-1">ការងារ៖</div>
            <div className="font-bold">{author.work}</div>
          </div>
          <div className="mr-8">
            <div className="mb-1">នៅ៖</div>
            <div className="font-bold">{author.location}</div>
          </div>
          <div className="">
            <div className="mb-1">បានចូលរួម៖</div>
            <div className="font-bold">{getDateInKhmer(author.joined)}</div>
          </div>
        </div>

        <div className="flex justify-center flex-wrap items-center">
          {github ? (
            <a
              className="flex flex-col items-center hover:text-red-400 shadow-none mr-4"
              href={github}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="fill-current font-bold h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>Github</title>
                <path d="M12 .5C5.37.5 0 5.78 0 12.292c0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56C20.565 21.917 24 17.495 24 12.292 24 5.78 18.627.5 12 .5z" />
              </svg>
              <div className="text-xs">Github</div>
            </a>
          ) : null}
          {medium ? (
            <a
              className="flex flex-col items-center hover:text-red-400 shadow-none mr-4"
              href={medium}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="fill-current font-bold h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>Medium</title>
                <path d="m24 24h-24v-24h24zm-8.986-15.006v7.326c0 .198 0 .234-.127.362l-1.302 1.264v.27h6.32v-.27l-1.257-1.234c-.091-.07-.148-.178-.148-.3 0-.022.002-.043.005-.064v.002-9.07c-.003-.019-.005-.04-.005-.062 0-.121.058-.229.148-.298l.001-.001 1.286-1.234v-.27h-4.456l-3.176 7.924-3.609-7.924h-4.675v.271l1.502 1.813c.127.115.207.281.207.466 0 .022-.001.043-.003.064v-.003 7.126c.007.041.011.088.011.136 0 .222-.088.423-.231.571l-1.69 2.054v.27h4.8v-.27l-1.691-2.054c-.149-.154-.241-.363-.241-.595 0-.04.003-.079.008-.117v.004-6.16l4.215 9.195h.49z" />
              </svg>
              <div className="text-xs">Medium</div>
            </a>
          ) : null}
          {devTo ? (
            <a
              className="flex flex-col items-center hover:text-red-400 shadow-none"
              href={devTo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="fill-current font-bold h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 32 447.99999999999994 448"
              >
                <title>Dev.to</title>
                <path d="M120.12 208.29c-3.88-2.9-7.77-4.35-11.65-4.35H91.03v104.47h17.45c3.88 0 7.77-1.45 11.65-4.35s5.82-7.25 5.82-13.06v-69.65c-.01-5.8-1.96-10.16-5.83-13.06zM404.1 32H43.9C19.7 32 .06 51.59 0 75.8v360.4C.06 460.41 19.7 480 43.9 480h360.2c24.21 0 43.84-19.59 43.9-43.8V75.8c-.06-24.21-19.7-43.8-43.9-43.8zM154.2 291.19c0 18.81-11.61 47.31-48.36 47.25h-46.4V172.98h47.38c35.44 0 47.36 28.46 47.37 47.28zm100.68-88.66H201.6v38.42h32.57v29.57H201.6v38.41h53.29v29.57h-62.18c-11.16.29-20.44-8.53-20.72-19.69V193.7c-.27-11.15 8.56-20.41 19.71-20.69h63.19zm103.64 115.29c-13.2 30.75-36.85 24.63-47.44 0l-38.53-144.8h32.57l29.71 113.72 29.57-113.72h32.58z" />
              </svg>
              <div className="text-xs">Dev.to</div>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Profile

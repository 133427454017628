import React from 'react'
import { graphql, navigate } from 'gatsby'
import kebabCase from 'kebab-case'
import PropTypes from 'prop-types'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import NoContent from '../components/no-content'
import Pagination from '../components/pagination'
import Profile from '../components/writer/profile'
import ArticleItem from '../components/writer/article-item'

const Writer = ({ data, location, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const counts = data.allMarkdownRemark.totalCount
  const author = data.site.siteMetadata.authors[0].name
  const { currentPage, hasNextPage, hasPreviousPage, perPage } =
    data.allMarkdownRemark.pageInfo
  return (
    <Layout location={location}>
      <SEO title={'Profile'} description={'Writer profile.'} />
      <div
        className="flex flex-col shadow-md pt-6 md:pt-12 px-4 md:px-12"
        style={{
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
      >
        <Profile />
        <div className="flex flex-col w-full pb-6">
          <div
            className="font-bold text-xl mb-2"
            style={{
              color: 'var(--textNormal)',
              transition: 'color 0.2s ease-out, background 0.2s ease-out'
            }}
          >
            អត្ថបទទាំងអស់ ({counts} អត្ថបទ)
          </div>
          <div
            className="w-full border-gray-900 border-0 border-b mb-4"
            style={{
              borderColor: 'var(--textNormal)',
              transition: 'borderColor 0.2s ease-out, background 0.2s ease-out'
            }}
          />
          {posts.length ? (
            posts.map((post, i) => <ArticleItem data={post} key={i} />)
          ) : (
            <NoContent />
          )}
        </div>
        <Pagination
          current={currentPage}
          total={counts}
          perPage={perPage}
          hasPrev={hasPreviousPage}
          hasNext={hasNextPage}
          load={(pageNum) =>
            navigate(`/writers/${kebabCase(author)}/${pageNum}`)
          }
          styleP="mt-0 mb-8"
        />
      </div>
    </Layout>
  )
}

Writer.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              readingTime: PropTypes.shape({
                minutes: PropTypes.number.isRequired
              }).isRequired
            }),
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              author: PropTypes.number.isRequired,
              featuredImage: PropTypes.shape({
                id: PropTypes.string.isRequired,
                publicURL: PropTypes.string.isRequired
              }),
              tags: PropTypes.array.isRequired
            }).isRequired
          })
        })
      ),
      totalCount: PropTypes.number,
      pageInfo: PropTypes.shape({
        currentPage: PropTypes.number,
        hasNextPage: PropTypes.bool,
        hasPreviousPage: PropTypes.bool,
        pageCount: PropTypes.number,
        perPage: PropTypes.number
      })
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        authors: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    })
  })
}

export default Writer

export const pageQuery = graphql`
  query WriterPostBySlug($id: Int, $limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { eq: $id }, published: { eq: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 625, truncate: true)
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            featuredImage {
              id
              publicURL
            }
            tags
          }
        }
      }
      totalCount
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        perPage
      }
    }
    site {
      siteMetadata {
        authors {
          name
        }
      }
    }
  }
`

import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { getDateInKhmer } from '../../utils'
import Tags from './tags'

const ArticleItem = ({ data }) => {
  const readTime = Math.ceil(data.node.fields.readingTime.minutes)
  const article = data.node.frontmatter
  const excerpt = data.node.excerpt
  const [hovered, setHovered] = useState(false)
  return (
    <div
      className="cursor-pointer shadow-none border-gray-900 border mb-4 p-6 rounded-sm"
      onClick={() => navigate(data.node.fields.slug)}
      style={{
        color: 'var(--textNormal)',
        borderColor: hovered ? '#fc8181' : 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`text-xl font-bold mb-3 ${hovered ? 'text-red-400' : ''}`}
      >
        {article.title}
      </div>
      <div
        className={`flex ${article.description ? 'mb-3' : ''} ${
          hovered ? 'text-red-400' : ''
        }`}
      >
        <div className="text-sm mr-5">
          ថ្ងៃទី៖ {getDateInKhmer(article.date)}
        </div>
        <div className="text-sm">ប្រវែង៖ {readTime} នាទី</div>
      </div>
      {article.tags.length ? <Tags tags={article.tags} /> : null}
      <div className={`mt-4 ${hovered ? 'text-red-400' : ''}`}>{excerpt}</div>
    </div>
  )
}

export default ArticleItem

import React from 'react'
import kebabCase from 'kebab-case'
import { to } from '../../utils'
import { colorSchemes } from '../../constants'

const Tags = ({ tags = [] }) => {
  return (
    <div className="flex items-center mt-3">
      {tags.map((tag, i) => {
        const colorScheme =
          colorSchemes[Math.floor(Math.random() * colorSchemes.length)]

        return (
          <div
            className={`text-xs cursor-pointer border shadow-none px-1 text-black hover:text-white ${
              colorScheme.bg
            } ${colorScheme.bd} ${i === 0 ? 'rounded-l-sm' : ''} ${
              i === tags.length - 1 ? 'rounded-r-sm' : ''
            }`}
            key={i}
            onClick={(e) => to(e, `/tags/${kebabCase(tag)}`)}
          >
            #{tag}
          </div>
        )
      })}
    </div>
  )
}

export default Tags

import React from 'react'

const NoContent = () => (
  <div className="flex flex-col flex-grow justify-center text-center mb-8">
    <svg
      className={`fill-current py-10 h-50-v md:h-30-v lg:h-30-v`}
      data-name="Found not"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 754 750.36"
    >
      <defs>
        <linearGradient
          id="a"
          x1="632.931"
          y1="457.002"
          x2="719.741"
          y2="457.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".3" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="578.931"
          y1="243.002"
          x2="665.741"
          y2="243.002"
        />
      </defs>
      <path
        d="M754 377a376.647 376.647 0 01-321.59 372.95H330.3c-.16.14-5.82.41-5.82.41-.95-.13-1.91-.27-2.86-.41q-10.905-1.605-21.61-3.83a372.653 372.653 0 01-81.03-26.75c-.27-.12-.53-.25-.8-.37a371.023 371.023 0 01-41.87-22.81c-.34-.21-.67-.42-1-.63-.57-.36-1.13-.71-1.69-1.08-.18-.11-.36-.22-.54-.34a376.057 376.057 0 01-34.67-25.24c-.54-.43-1.08-.88-1.62-1.32q-3.255-2.7-6.45-5.46c-.02-.01-.03-.03-.05-.04A380.652 380.652 0 0199.32 632c-.03-.04-.07-.07-.1-.11a376.239 376.239 0 01-88.33-164.6c-.12-.44-.22-.87-.33-1.31A378.139 378.139 0 010 377C0 168.79 168.79 0 377 0s377 168.79 377 377z"
        fill="#3f3d56"
      />
      <path
        d="M634.853 507.436l32.971-38.598 32.97-38.599 18.613-21.79c1.03-1.205-.56-3.086-1.59-1.88l-32.97 38.598-32.97 38.599-18.613 21.79c-1.03 1.205.56 3.086 1.59 1.88z"
        transform="translate(-223 -74.82)"
        opacity=".8"
        fill="url(#a)"
      />
      <path fill="#2f2e41" d="M386.81 336.41h34.1v413.54h-34.1z" />
      <path
        fill="#fc8181"
        d="M409.95 488.23l-.07 12.38-.83 150.26-.1 17.91-.22 40.36-.22 40.81h-2.2l.22-40.81.21-37.7.11-20.94.83-149.86.07-12.42 2.2.01z"
      />
      <path
        d="M427.03 341.91h-46.203c-.67-7.238.998-14.528 5.294-21.876l1.306-8.927h31.903l1.505 8.814c4.47 5.956 6.202 13.476 6.196 21.989z"
        fill="#2f2e41"
      />
      <path
        d="M420.318 312.207h-32.922a2.2 2.2 0 01-2.14-2.713l5.545-23.102a2.2 2.2 0 012.14-1.687h21.833a2.2 2.2 0 012.14 1.687l5.544 23.102a2.2 2.2 0 01-2.14 2.713z"
        fill="#2f2e41"
      />
      <path
        d="M403.86 273.15a11.564 11.564 0 00-11.55 11.56v11.55h23.1v-11.55a11.564 11.564 0 00-11.55-11.56zM391.051 622.109l-78.286 101.605 10.827 2.499 60.796-78.286h6.663v-25.818zM419.831 622.109l78.286 101.605-10.827 2.499-60.796-78.286h-6.663v-25.818zM649.38 245.25v392.4a378.724 378.724 0 01-82.45 65.08V245.25z"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M634.387 295.224h54.134v54.134h-54.134zM601.906 114.5h12.492v141.581h-12.492z"
      />
      <g opacity=".4" fill="#e6e6e6">
        <path d="M573.93 245.25h-7v457.48q3.527-2.061 7-4.205zM601.906 114.5h7v130.75h-7z" />
      </g>
      <path fill="#fc8181" d="M583.168 309.798h44.973v24.985h-44.973z" />
      <circle cx="146.933" cy="244.847" r="66.193" fill="#e6e6e6" />
      <circle cx="140.423" cy="199.271" r="7.596" fill="#cbcbcb" />
      <circle cx="153.444" cy="275.23" r="7.596" fill="#cbcbcb" />
      <circle cx="182.742" cy="232.91" r="5.426" fill="#cbcbcb" />
      <circle cx="120.89" cy="250.272" r="17.362" fill="#cbcbcb" />
      <circle cx="252.015" cy="349.213" r="3.576" fill="#fc8181" />
      <path
        fill="#e6e6e6"
        d="M283.012 401.093l-1.785 1.773-1.773-1.785-1.19 1.182 1.773 1.785-1.785 1.773 1.182 1.19 1.785-1.773 1.773 1.785 1.19-1.182-1.773-1.785 1.785-1.773-1.182-1.19zM297.318 297.375l-1.785 1.773-1.773-1.785-1.19 1.182 1.773 1.785-1.785 1.773 1.182 1.19 1.785-1.773 1.773 1.785 1.19-1.182-1.773-1.785 1.785-1.773-1.182-1.19zM342.279 100.556c-3.862 2.573-7.815-3.551-3.878-6.01 3.861-2.573 7.814 3.551 3.878 6.01zM118.557 366.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM494.557 71.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM686.557 230.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM222.46 110.506l-2.457-.539.538-2.457-1.638-.359-.539 2.457-2.458-.538-.359 1.638 2.458.539-.538 2.458 1.638.358.539-2.457 2.457.538.359-1.638z"
      />
      <circle cx="455.34" cy="188.551" r="3.576" fill="#fc8181" />
      <path
        fill="#e6e6e6"
        d="M428.557 242.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM523.46 198.506l-2.457-.539.538-2.457-1.638-.359-.539 2.457-2.458-.538-.359 1.638 2.458.539-.538 2.458 1.638.358.539-2.457 2.457.538.359-1.638z"
      />
      <path
        d="M580.853 293.436l32.971-38.598 32.97-38.599 18.613-21.79c1.03-1.205-.56-3.086-1.59-1.88l-32.97 38.598-32.97 38.599-18.613 21.79c-1.03 1.205.56 3.086 1.59 1.88z"
        transform="translate(-223 -74.82)"
        opacity=".8"
        fill="url(#b)"
      />
      <path
        fill="#e6e6e6"
        d="M385.494 646.969h6v102.023h-6zM405.54 272.6a11.573 11.573 0 00-8.55 11.15v.1a2.211 2.211 0 00-1.51 1.58l-5.54 23.11a2.185 2.185 0 002.01 2.69l-.04.27-.15 1-2.26 7c-4.29 7.34-4.66 14.21-3.99 21.45h5.98v280.51l-78.04 101.3 5.74 1.32-.91 1.18-10.83-2.5 78.04-101.3V340.95l-4.99-.45c-.67-7.24.71-13.66 5-21l.26-7 .15-1 .04-.27a2.185 2.185 0 01-2.01-2.69l5.54-23.11a2.211 2.211 0 011.51-1.58v-.1a11.564 11.564 0 0111.55-11.56 11.433 11.433 0 013 .41zM431.177 646.969h-5.583v.537l60.38 77.749 5.088-1.174-59.885-77.112z"
      />
      <path
        d="M514.88 709.14v18.85a374.502 374.502 0 01-82.47 21.96h-123c-3.15-.57-6.29-1.18-9.4-1.83v-38.98z"
        fill="#2f2e41"
      />
      <path
        d="M409.05 650.87c-.7-.12-1.44-.24-2.2-.37-4.47-.72-9.86-1.54-16.04-2.39q-.63-.09-1.29-.18c-5.1-.69-10.71-1.39-16.76-2.08-33.17-3.73-79.5-6.72-126.78-2.61 1.17-4.7 2.25-9.4 3.27-14.08l-45.28 7.01 48.86-24.85a692.681 692.681 0 009.48-77.18l-24.46 3.78 25.01-12.72c1.79-32.03.95-53.35.95-53.35S182 514.38 116.58 579.38c-2.4-8.46-5.03-16.81-7.83-25.01l-35.14 29.4 28.97-46.52a689.2 689.2 0 00-31.78-70.97l-18.98 15.88 14.84-23.82c-15.02-28.35-26.76-46.17-26.76-46.17s-13.39 21.94-29.34 55.81A376.303 376.303 0 0099.32 634a380.108 380.108 0 0031.02 30.12q3.195 2.76 6.45 5.46a377.473 377.473 0 0036.29 26.56c.74.48 1.48.95 2.23 1.42a375.117 375.117 0 00124.7 50.56c3.11.65 6.25 1.26 9.4 1.83.77.14 1.54.28 2.31.41h12.76s5.66-.27 5.82-.41c15.85-13.87 30.22-27.89 42.61-40.81 2.82-2.93 5.53-5.81 8.14-8.61l-21.38-12.47 27.44 5.88a735.125 735.125 0 0019.63-22.5c.76-.92 1.5-1.8 2.21-2.66 8.07-9.74 12.51-15.68 12.51-15.68s-4.46-.91-12.41-2.23z"
        fill="#2f2e41"
      />
      <path
        d="M385.49 340.95h-5.98c-.67-7.24 1-14.53 5.29-21.87l.96-6.58.15-1h6l-.15 1-.96 6.58c-4.29 7.34-5.96 14.63-5.29 21.87h5.98M385.49 427.5h6v73h-6z"
        fill="#fc8181"
      />
      <circle cx="606.5" cy="114.5" r="11" fill="#fc8181" />
    </svg>
    <div className="text-xl  mb-4">Good thing is yet to come!</div>
  </div>
)

export default NoContent
